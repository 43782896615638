<template>
  <b-container class="min-vh-100 d-flex flex-column">
    <BackButton @click="$router.push('/bikeinfo')" />
    <b-row>
      <b-col cols="1" />
      <b-col cols="9">
        <h1 style="margin-top: 4.25rem; margin-bottom: 2rem;">{{$store.state.bike.name}}</h1>
      </b-col>
      <b-col cols="1">
        <BatteryIcon v-if="$store.state.bike.type==='ebike'||1/* remove or*/" :charging="$store.state.bike.charging"
          :power="$store.state.bike.power" />
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <p>Das Sichern des Fahrrads wird von deinem wöchentlichen Zeitbudget abgezogen.</p>
        <p style="font-family: ArchiaSemiBold, Helvetica, Arial, sans-serif;">Für 30 min reservieren?</p>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row style="text-align: center;">
        <b-col cols="2" />
        <b-col cols="8">
          <img src="@/assets/illustrations/thinking.svg" class="illustration" alt="biker"
            style="width: 100%; height: 100%; margin-top: 0.5rem; margin-bottom: 2rem;" />
        </b-col>
        <b-col cols="2" />
      </b-row>
    <b-row style="text-align: center;" class="flex-grow-1">
      <b-col cols="1" />
      <b-col cols="10" class="justify-content-center align-self-center">
        <b-button variant="primary" class="buttonSpacing" @click="reserveBike">Reservieren</b-button>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row v-if="false">
      <p class="bikeDebugInfo" v-if="$store.state.userProfile.debug">{{$store.state.bike.ownerId}} &gt; {{$store.state.bike._id}}</p>
    </b-row>
  </b-container>
</template>

<script>
  import BackButton from '../components/BackButton';
  import BatteryIcon from '../components/BatteryIcon';
  export default {
    name: 'BikeReserveInfo',
    data() {
      return {}
    },
    components: {
      BackButton,
      BatteryIcon,
    },
    methods: {
      reserveBike() {
        this.$router.push('/bikereserve');
      }
    },
    mounted() {
      // checks if the page loaded without any bike data
      if (Object.keys(this.$store.state.bike).length === 0) {
        this.$router.push('/').then(() => this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut',
          'danger'));
      }
    }
  }
</script>

<style scoped>
  .bikeDebugInfo {
    position: absolute;
    bottom: 0px;
    left: 20px;
  }
</style>